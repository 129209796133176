import { createApp } from 'vue';
import App from './app.vue';

import 'bootstrap';
import '../../../public/css/public-sans.css';
import '@fortawesome/fontawesome-free/css/all.css';

const app = createApp({
  extends: App
})

app.mount('#app');